import React from 'react';

import styles from './styles.module.scss';

import SEO from '../../components/seo';
import Hero from '../../components/hero';
import PrimaryButton from '../../components/primary-button';
import { Link } from 'gatsby';

export default function NotFoundPage() {
  return (
    <React.Fragment>
      <SEO title="Ben je verdwaald?"></SEO>
      <div className={styles.not_found}>
        <Hero className={styles.not_found__hero} title="Ben je verdwaald?">
          <Link to="/">
            <PrimaryButton>Breng me terug</PrimaryButton>
          </Link>
        </Hero>
      </div>
    </React.Fragment>
  );
}
